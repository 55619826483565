const tocLatest = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Previous Releases',
    link: '/home/all-docs',
    children: [
      {
        name: 'Cloud Docs',
        link: '/cloud',
      },
      {
        name: '6.0 Docs',
        link: '/6.0',
      },
      {
        name: '5.2 Docs',
        link: '/5.2',
      },
      {
        name: '5.1 Docs',
        link: '/5.1',
      },
      {
        name: 'Legacy Releases',
        link: 'https://docs4.incorta.com',
      },
    ],
  },
  {
    name: 'Latest Release',
    link: '/latest',
  },
  {
    name: 'Guides',
    link: '/latest/guides-all',
    children: [
      {
        name: 'Start',
        link: '/latest/guides-start',
      },
      {
        name: 'Administer',
        link: '/latest/admin',
        children: [
          {
            name: 'Nodes, Clusters, and Services',
            link: '/latest/nodes-clusters-services',
          },
          {
            name: 'Cluster Management Console',
            link: '/latest/cmc',
          },
          {
            name: 'Optimize performance',
            link: '/latest/optimize-performance',
          },
          {
            name: 'Performance Tuning',
            link: '/latest/g-performance-tuning',
          },
          {
            name: 'User Management',
            link: '/latest/user-admin',
          },
        ],
      },
      {
        name: 'Analyze',
        link: '/latest/guides-analyze',
      },
      {
        name: 'Configure',
        link: '/latest/config',
        children: [
          {
            name: 'Configure Server',
            link: '/latest/guides-configure-server',
          },
          {
            name: 'Configure Tenants',
            link: '/latest/guides-configure-tenants',
          },
          {
            name: 'Email Configuration',
            link: '/latest/email',
          },
          {
            name: 'Configure a Tenant on ADLS Gen2',
            link: '/latest/guides-configure-a-tenant-on-adls-gen2',
          },
          {
            name: 'Apache Web Server Configuration',
            link: '/latest/apache',
          },
          {
            name: 'Zookeeper Cluster Configuration',
            link: '/latest/zookeeper',
          },
          {
            name: 'Memory Usage',
            link: '/latest/ebs-memory-usage',
          },
          {
            name: 'Configure High Availability',
            link: '/latest/ha',
          },
          {
            name: 'Memory Configuration',
            link: '/latest/memory-config',
          },
        ],
      },
      {
        name: 'Deploy',
        link: '/latest/deploy',
        children: [
          {
            name: 'Deploy on AWS',
            link: '/latest/aws-use',
            children: [
              {
                name: 'Set Up an AWS Account',
                link: '/latest/aws-setup',
              },
              {
                name: 'Create an AWS tag',
                link: '/latest/tagging',
              },
              {
                name: 'Create an IAM Role and Policy',
                link: '/latest/iam',
              },
              {
                name: 'Security Group Open ports',
                link: '/latest/aws-security',
              },
              {
                name: 'Create an Encryption Key with AWS KMS Servic',
                link: '/latest/aws-kms',
              },
              {
                name: 'Create an EBS Volume',
                link: '/latest/aws-ebs',
              },
              {
                name: 'Create an S3 Bucket',
                link: '/latest/aws-s3',
              },
              {
                name: 'Create E2C Instance',
                link: '/latest/aws-e2c',
              },
              {
                name: 'Secure Shell in to Incorta',
                link: '/latest/aws-ec2-connect',
              },
              {
                name: 'Start Incorta',
                link: '/latest/aws-ssh-connect',
              },
              {
                name: 'Connect to Incorta',
                link: '/latest/aws-access-cmc',
              },
            ],
          },
        ],
      },
      {
        name: 'Install',
        link: '/latest/install',
        children: [
          {
            name: 'Installation Requirements',
            link: '/latest/install-requirements',
            children: [
              {
                name: 'Install Java',
                link: '/latest/install-java',
              },
            ],
          },
          {
            name: 'Install on a Single Node',
            link: '/latest/install-single-server',
          },
          {
            name: 'Finish Installing Incorta with the CMC',
            link: '/latest/install-cmc',
          },
          {
            name: 'Installation Troubleshooting',
            link: '/latest/install-troubleshoot',
          },
          {
            name: 'Install in the Cloud',
            link: '/latest/run-cloud',
            children: [
              {
                name: 'AWS High Availability Incorta Cluster',
                link: '/latest/g-aws-ha-incorta-cluster-deploy',
              },
            ],
          },
        ],
      },
      {
        name: 'Migrate',
        link: '/latest/guides-migrate',
      },
      {
        name: 'Organize',
        link: '/latest/guides-organize-content',
      },
      {
        name: 'Secure',
        link: '/latest/guides-secure',
        children: [
          {
            name: 'Incorta Security Guide',
            link: '/latest/incorta-security-guide',
          },
          {
            name: 'Secure Communication and Data',
            link: '/latest/system-security',
            children: [
              {
                name: 'Secure Tomcat',
                link: '/latest/secure-tomcat',
              },
              {
                name: 'Secure Zookeeper',
                link: '/latest/secure-zookeeper',
              },
              {
                name: 'Encrypt Metadata Database Password',
                link: '/latest/encrypt-md-db-password',
              },
              {
                name: 'Setting the Session Timeout',
                link: '/latest/t-set-session-timeout',
              },
              {
                name: 'Data Encryption',
                link: '/latest/guides-data-encryption',
              },
            ],
      },
      {
        name: 'Configure SSO',
        link: '/latest/guides-single-sign-on-configurations',
        children: [
          {
            name: 'Enable and Test SSO',
            link: '/latest/enable-sso',
          },
          {
            name: 'Use Incorta Login (Self-Sync)',
            link: '/latest/self-sync',
          },
          {
            name: 'Azure AD SSO',
            link: '/latest/guides-azure-ad-sso-configuration',
          },
          {
            name: 'ADFS SSO',
            link: '/latest/guides-adfs-sso-configuration',
          },
          {
            name: 'Auth0 SSO',
            link: '/latest/guides-auth0-sso-configuration',
          },
          {
            name: 'IBM CIS SSO',
            link: '/latest/guides-ibm-cis-sso-configuration',
          },
          {
            name: 'Okta SSO',
            link: '/latest/guides-okta-sso-configuration',
          },
          {
            name: 'OneLogin SSO',
            link: '/latest/guides-onelogin-sso-configuration',
          },
          {
            name: 'Mobile SSO',
            link: '/latest/mobile-sso',
          },
          {
            name: 'Configure LDAP',
            link: '/latest/ldap',
          },
          {
            name: 'Monitor Logins and Access',
            link: '/latest/monitor-security',
          },
        ],
      },
      {
        name: 'Secure Objects and Business Data',
        link: '/latest/data-security',
        children: [
          {
            name: 'Security Roles',
            link: '/latest/references-security-roles',
          },
          {
            name: 'Reset Superuser Password',
            link: '/latest/reset-super-admin',
          },
          {
            name: 'Set Read-only Schema Access',
            link: '/latest/read-only-schema',
          },
          {
            name: 'Runtime Security Filter',
            link: '/latest/concepts-runtime-security-filter',
          },
          {
            name: 'Session Variables',
            link: '/latest/concepts-variable',
          },
          {
            name: 'SOX Compliance',
            link: '/latest/sox',
      },
    ],
  },
],
},
      {
        name: 'Upgrade',
        link: '/latest/guides-upgrade',
        children: [
          {
            name: 'Guides → Upgrade from 5.0.x to 2024.1.3 OP',
            link: '/latest/guides-upgrade-from-5-0-x-to-2024-1-3',
          },
          {
            name: 'Guides → Upgrade from 5.1.x or 5.2.x to 2024.1.3 OP',
            link: '/latest/guides-upgrade-from-5-x-to-2024-1-3',
          },
          {
            name: 'Guides → Upgrade from 6.0 to 2024.1.3 OP',
            link: '/latest/guides-upgrade-from-6-x-to-2024-1-3',
          },
          {
            name: 'AWS High Availability Incorta',
            link: '/latest/g-aws-ha-incorta-cluster-upgrade',
          },
          {
            name: ' Metadata Database Migration',
            link: '/latest/guides-metadata-database-migration',
          },
        ],
      },
      {
        name: 'Visualize',
        link: '/latest/guides-visualizations',
      },
    ],
  },
  {
    name: 'Concepts',
    link: '/latest/concepts-all',
    children: [
      {
        name: 'Aggregate filter',
        link: '/latest/concepts-aggregate-filter',
      },
      {
        name: 'Alias',
        link: '/latest/concepts-alias',
      },
      {
        name: 'Apache Avro™',
        link: '/latest/concepts-apache-avro',
      },
      {
        name: 'Apache Kafka™',
        link: '/latest/concepts-apache-kafka',
      },
      {
        name: 'Applied filter',
        link: '/latest/concepts-applied-filter',
      },
      {
        name: 'Base table',
        link: '/latest/concepts-base-table',
      },
      {
        name: 'Bookmark',
        link: '/latest/concepts-bookmark',
      },
      {
        name: 'Business schema',
        link: '/latest/concepts-business-schema',
      },
      {
        name: 'Business schema view',
        link: '/latest/concepts-business-schema-view',
      },
      {
        name: 'Business schema view column',
        link: '/latest/concepts-business-schema-view-column',
      },
      {
        name: 'Business schema view formula column',
        link: '/latest/concepts-business-schema-view-formula-column',
      },
      {
        name: 'Column',
        link: '/latest/concepts-column',
      },
      {
        name: 'Connector',
        link: '/latest/concepts-connector',
      },
      {
        name: 'Dashboard filter',
        link: '/latest/concepts-dashboard-filter',
      },
      {
        name: 'Dashboard folder',
        link: '/latest/concepts-dashboard-folder',
      },
      {
        name: 'Dashboard runtime filter',
        link: '/latest/concepts-dashboard-runtime-filter',
      },
      {
        name: 'Data agent',
        link: '/latest/concepts-data-agent',
      },
      {
        name: 'Data alert',
        link: '/latest/concepts-data-alert',
      },
      {
        name: 'Data destination',
        link: '/latest/concepts-data-destination',
      },
      {
        name: 'Date system variable',
        link: '/latest/concepts-date-system-variable',
      },
      {
        name: 'Distinct filter',
        link: '/latest/concepts-distinct-filter',
      },
      {
        name: 'Dynamic Field',
        link: '/latest/concepts-dynamic-field',
      },
      {
        name: 'Expression',
        link: '/latest/concepts-expression',
      },
      {
        name: 'External data source',
        link: '/latest/concepts-external-data-source',
      },
      {
        name: 'External session variable',
        link: '/latest/concepts-external-session-variable',
      },
      {
        name: 'Filter expression',
        link: '/latest/concepts-filter-expression',
      },
      {
        name: 'Filter expression session variable',
        link: '/latest/concepts-filter-expression-session-variable',
      },
      {
        name: 'Filter option',
        link: '/latest/concepts-filter-option',
      },
      {
        name: 'Formula column',
        link: '/latest/concepts-formula-column',
      },
      {
        name: 'Formula expression',
        link: '/latest/concepts-formula-expression',
      },
      {
        name: 'Full load',
        link: '/latest/concepts-full-load',
      },
      {
        name: 'Global variable',
        link: '/latest/concepts-global-variable',
      },
      {
        name: 'Incorta Analyzer Table',
        link: '/latest/concepts-incorta-analyzer-table',
      },
      {
        name: 'Incorta Analyzer Table column',
        link: '/latest/concepts-incorta-analyzer-table-column',
      },
      {
        name: 'Incorta Analyzer Table formula column',
        link: '/latest/concepts-incorta-analyzer-table-formula-column',
      },
      {
        name: 'Incorta Analyzer View',
        link: '/latest/concepts-incorta-view',
      },
      {
        name: 'Incorta Analyzer View formula column',
        link: '/latest/concepts-incorta-view-formula-column',
      },
      {
        name: 'Incorta Spark SQL View',
        link: '/latest/concepts-incorta-spark-sql-view',
      },
      {
        name: 'Incorta SQL Table',
        link: '/latest/concepts-incorta-sql-table',
      },
      {
        name: 'Individual filter',
        link: '/latest/concepts-individual-filter',
      },
      {
        name: 'Insight filter',
        link: '/latest/concepts-insight-filter',
      },
      {
        name: 'Internal query expression',
        link: '/latest/concepts-internal-query-expression',
      },
      {
        name: 'Internal session variable',
        link: '/latest/concepts-internal-session-variable',
      },
      {
        name: 'Join',
        link: '/latest/concepts-join',
      },
      {
        name: 'Join condition',
        link: '/latest/concepts-join-condition',
      },
      {
        name: 'Join condition column',
        link: '/latest/concepts-join-condition-column',
      },
      {
        name: 'Join filter',
        link: '/latest/concepts-join-filter',
      },
      {
        name: 'Join filter column',
        link: '/latest/concepts-join-filter-column',
      },
      {
        name: 'Level based measure',
        link: '/latest/concepts-level-based-measure',
      },
      {
        name: 'Load filter',
        link: '/latest/concepts-load-filter',
      },
      {
        name: 'Local data file',
        link: '/latest/concepts-local-data-file',
      },
      {
        name: 'Local data folder',
        link: '/latest/concepts-local-data-folder',
      },
      {
        name: 'Materialized View',
        link: '/latest/concepts-materialized-view',
      },
      {
        name: 'Materialized View Column',
        link: '/latest/concepts-materialized-view-column',
      },
      {
        name: 'Materialized View Formula Column',
        link: '/latest/concepts-materialized-view-formula-column',
      },
      {
        name: 'Measure filter',
        link: '/latest/concepts-measure-filter',
      },
      {
        name: 'Miscellaneous system variable',
        link: '/latest/concepts-miscellaneous-system-variable',
      },
      {
        name: 'Multi-Source table',
        link: '/latest/concepts-multi-source-table',
      },
      {
        name: 'Physical schema',
        link: '/latest/concepts-physical-schema',
      },
      {
        name: 'Physical schema table',
        link: '/latest/concepts-physical-schema-table',
      },
      {
        name: 'Physical schema table column',
        link: '/latest/concepts-physical-schema-table-column',
      },
      {
        name: 'Physical schema table formula column',
        link: '/latest/concepts-physical-schema-table-formula-column',
      },
      {
        name: 'Physical schema table runtime security filter',
        link: '/latest/concepts-physical-schema-table-runtime-security-filter',
      },
      {
        name: 'PostgreSQL (Incorta SQL) View',
        link: '/latest/concepts-incorta-sql-view',
      },
      {
        name: 'Presentation variable',
        link: '/latest/concepts-presentation-variable',
      },
      {
        name: 'Prompt',
        link: '/latest/concepts-prompt',
      },
      {
        name: 'Query system variable',
        link: '/latest/concepts-query-system-variable',
      },
      {
        name: 'Runtime business view',
        link: '/latest/concepts-runtime-business-view',
      },
      {
        name: 'Runtime business view formula column',
        link: '/latest/concepts-runtime-business-view-formula-column',
      },
      {
        name: 'Runtime security filter',
        link: '/latest/concepts-runtime-security-filter',
      },
      {
        name: 'Schema evolution',
        link: '/latest/concepts-schema-evolution',
      },
      {
        name: 'Session variable',
        link: '/latest/concepts-session-variable',
      },
      {
        name: 'Shared Storage',
        link: '/latest/concepts-shared-storage',
      },
      {
        name: 'Upgrade Readiness',
        link: '/latest/concepts-upgrade-readiness',
      },
      {
        name: 'Variable',
        link: '/latest/concepts-variable',
      },
      {
        name: 'Visualization',
        link: '/latest/concepts-visualization',
      },
    ],
  },
  {
    name: 'Tools',
    link: '/latest/tools-all',
    children: [
      {
        name: 'Alert Editor',
        link: '/latest/tools-alert-editor',
      },
      {
        name: 'Alias Sync Tool',
        link: '/latest/tools-alias-sync-tool',
      },
      {
        name: 'Analyzer',
        link: '/latest/tools-analyzer',
      },
      {
        name: 'Apache Parquet Merge Tool',
        link: '/latest/tools-apache-parquet-merge-tool',
      },
      {
        name: 'Avro Extractor Tool',
        link: '/latest/tools-avro-extractor-tool',
      },
      {
        name: 'Business Schema Designer',
        link: '/latest/tools-business-schema-designer',
      },
      {
        name: 'Business Schema Manager',
        link: '/latest/tools-business-schema-manager',
      },
      {
        name: 'Cluster Management Console',
        link: '/latest/cmc',
        children: [
          {
            name: 'SSO for Tenant from CMC',
            link: '/latest/cmc-sso',
          },
          {
            name: 'Pause Load Jobs from CMC',
            link: '/latest/cmc-jobs',
          },
          {
            name: 'Use Warmup Mode',
            link: '/latest/cmc-warmup',
          },
        ],
      },
      {
        name: 'CMC Logs Manager',
        link: '/latest/tools-cmc-logs-manager',
      },
      {
        name: 'CMC Monitoring',
        link: '/latest/tools-cmc-monitoring',
      },
      {
        name: 'CMC Patch Manager',
        link: '/latest/tools-cmc-patch-manager',
      },
      {
        name: 'CMC Scheduler',
        link: '/latest/tools-cmc-scheduler',
      },
      {
        name: 'CMC Tenant Manager',
        link: '/latest/tools-cmc-tenant-manager',
      },
      {
        name: 'Command Line Interface',
        link: '/latest/tools-cli',
      },
      {
        name: 'Content Manager',
        link: '/latest/tools-content-manager',
      },
      {
        name: 'Dashboard Filters Manager',
        link: '/latest/tools-dashboard-filters-manager',
      },
      {
        name: 'Dashboard Manager',
        link: '/latest/tools-dashboard-manager',
      },
      {
        name: 'Data Agent',
        link: '/latest/tools-data-agent',
      },
      {
        name: 'Data APIs for External Notebooks',
        link: '/latest/tools-data-apis-for-external-notebooks',
      },
      {
        name: 'Data Lineage Viewer v2',
        link: '/latest/tools-data-lineage-viewer-v2',
      },
      {
        name: 'Data Manager',
        link: '/latest/tools-data-manager',
      },
      {
        name: 'Formula Builder',
        link: '/latest/tools-formula-builder',
      },
      {
        name: 'Inspector Tool',
        link: '/latest/tools-inspector-tool',
      },
      {
        name: 'Join Editor',
        link: '/latest/tools-join-editor',
      },
      {
        name: 'Load Job Details Viewer',
        link: '/latest/tools-load-job-details-viewer',
      },
      {
        name: 'Load Plan DAG Viewer',
        link: '/latest/tools-load-plan-dag-viewer',
      },
      {
        name: 'Model Update Viewer',
        link: '/latest/tools-model-update-viewer',
      },
      {
        name: 'Notebook Editor',
        link: '/latest/tools-notebook-editor',
      },
      {
        name: 'Profile Manager',
        link: '/latest/tools-profile-manager',
      },
      {
        name: 'Public API',
        link: '/latest/tools-public-api',
      },
      {
        name: 'Query Plan Viewer',
        link: '/latest/tools-query-plan-viewer',
      },
      {
        name: 'Scheduler',
        link: '/latest/tools-scheduler',
      },
      {
        name: 'Schema Designer',
        link: '/latest/tools-schema-designer',
        children: [
          {
            name: 'Schema Designer Joins',
            link: '/latest/tools-schema-designer-joins',
          },
          {
            name: 'Schema Designer Load Jobs',
            link: '/latest/tools-schema-designer-load-jobs',
          },
          {
            name: 'Schema Designer Modes and Versions',
            link: '/latest/tools-schema-designer-modes-and-versions',
          },
          {
            name: 'Schema Designer Tables',
            link: '/latest/tools-schema-designer-tables',
          },
        ],
      },
      {
        name: 'Schema Diagram Viewer',
        link: '/latest/tools-schema-diagram-viewer',
      },
      {
        name: 'Schema Manager',
        link: '/latest/tools-schema-manager',
        children:
        [
          {
            name: 'Schemas',
            link: '/latest/tools-schema-manager-schemas',
          },
          {
            name: 'Load Jobs',
            link: '/latest/tools-schema-manager-load-jobs',
          },
          {
            name: 'Session Variables',
            link: '/latest/tools-schema-manager-session-variables',
          },
          {
            name: 'Global Variables',
            link: '/latest/tools-schema-manager-global-variables',
          },
        ],
      },
      {
        name: 'Schema Wizard',
        link: '/latest/tools-schema-wizard',
      },
      {
        name: 'Security Manager',
        link: '/latest/tools-security-manager',
      },
      {
        name: 'Table Editor',
        link: '/latest/tools-table-editor',
      },
      {
        name: 'Tenant Management Tool',
        link: '/latest/tools-tenant-management-tool',
        children: [
          {
            name: 'Export and Import Objects',
            link: '/latest/export-objects',
          },
          {
            name: 'Export a Tenant',
            link: '/latest/export-tenant',
          },
          {
            name: 'Import a Tenant',
            link: '/latest/import-tenant',
          },
          {
            name: 'Migrate Scheduled Jobs for DST',
            link: '/latest/tools-tenant-management-tool-dst',
          },
          {
            name: 'Backup and Restore',
            link: '/latest/backup-restore',
          },
        ],
      },
      {
        name: 'Upload Service Command Line Interface (CLI)',
        link: '/latest/tools-upload-service-cli',
      },
      {
        name: 'Versioning Migration Tool',
        link: '/latest/tools-versioning-migration-tool',
      },
    ],
  },
  {
    name: 'References',
    link: '/latest/references-all',
    children: [
      {
        name: 'Apache Spark',
        link: '/latest/spark',
        children: [
          {
            name: 'Using Spark with Incorta',
            link: '/latest/spark-what',
          },
          {
            name: 'Spark Architecture',
            link: '/latest/spark-arc',
          },
          {
            name: 'Spark Application Model',
            link: '/latest/spark-app-model',
          },
          {
            name: 'Running Spark Applications',
            link: '/latest/spark-running',
          },
          {
            name: 'Configure Standalone Spark',
            link: '/latest/spark-config',
          },
          {
            name: 'Configure Spark for Hadoop on Windows',
            link: '/latest/spark-hadoop-windows',
          },
          {
            name: 'Configure Spark for Use with Materialized Views',
            link: '/latest/spark-config-mv',
          },
          {
            name: 'Use the SQL Interface with Spark',
            link: '/latest/spark-config-sqli',
          },
          {
            name: 'Enable SSL Certificate in SQLi',
            link: '/latest/enable-ssl-sqli',
          },
          {
            name: 'Troubleshoot Spark',
            link: '/latest/spark-troubleshoot',
          },
        ],
      },
{
        name: 'Built-in Functions',
        link: '/latest/references-built-in-functions',
        children: [
          {
            name: 'Aggregation Functions',
            link: '/latest/references-built-in-functions-aggregation',
          },
          {
            name: 'Analytic Functions',
            link: '/latest/references-built-in-functions-analytic',
          },
          {
            name: 'Boolean Functions',
            link: '/latest/references-built-in-functions-boolean',
          },
          {
            name: 'Conditional Statements',
            link: '/latest/references-built-in-functions-conditional',
          },
          {
            name: 'Conversion Functions',
            link: '/latest/references-built-in-functions-conversion',
          },
          {
            name: 'Filter Functions',
            link: '/latest/references-built-in-functions-filter',
          },
          {
            name: 'Miscellaneous Functions',
            link: '/latest/references-built-in-functions-miscellaneous',
          },
          {
            name: 'Arithmetic Functions',
            link: '/latest/references-built-in-functions-arithmetic',
          },
          {
            name: 'Date Functions',
            link: '/latest/references-built-in-functions-date',
          },
          {
            name: 'String Functions',
            link: '/latest/references-built-in-functions-string',
          },
          {
            name: 'Query Functions',
            link: '/latest/references-built-in-functions-query',
          },
        ],
      },
      {
        name: 'Incorta Component SDK',
        link: '/latest/references-component-sdk',
        children: [
          {
            name: 'Overview',
            link: '/latest/references-component-sdk-overview',
          },
          {
            name: 'Getting Started',
            link: '/latest/references-component-sdk-getting-started',
          },
          {
            name: 'Start Development',
            link: '/latest/references-component-sdk-start-development',
          },
          {
            name: 'Definition.json',
            link: '/latest/references-component-sdk-definitions',
          },
          {
            name: 'PrivateData API',
            link: '/latest/references-component-sdk-privatedata-api',
          },
        ],
      },
      {
        name: 'Incorta Connector SDK',
        link: '/latest/references-connector-sdk',
      },
      {
        name: 'Connectors',
        link: '/latest/references-connectors-all',
        children: [
          {
            name: 'AlloyDB',
            link: '/latest/references-connectors-alloydb',
          },
          {
            name: 'Amazon Web Services (AWS) Athena',
            link: '/latest/references-connectors-amazon-web-services-aws-athena',
          },
          {
            name: 'Amazon Web Services (AWS) DynamoDB',
            link: '/latest/references-connectors-amazon-web-services-aws-dynamodb',
          },
          {
            name: 'Amazon Web Services (AWS) RedShift',
            link: '/latest/references-connectors-amazon-web-services-aws-redshift',
          },
          {
            name: 'Amazon Web Services (AWS) S3',
            link: '/latest/references-connectors-amazon-web-services-aws-s3',
          },
          {
            name: 'Apache Drill',
            link: '/latest/references-connectors-apache-drill',
          },
          {
            name: 'Apache Hadoop HDFS',
            link: '/latest/references-connectors-apache-hadoop',
          },
          {
            name: 'Apache Hive',
            link: '/latest/references-connectors-apache-hive',
          },
          {
            name: 'Apache Kafka',
            link: '/latest/references-connectors-apache-kafka',
          },
          {
            name: 'Autoline',
            link: '/latest/references-connectors-autoline',
          },
          {
            name: 'Box',
            link: '/latest/references-connectors-box',
          },
          {
            name: 'Cassandra (Simba)',
            link: '/latest/references-connectors-cassandra-simba',
          },
          {
            name: 'Cassandra (Incorta)',
            link: '/latest/references-connectors-cassandra',
          },
          {
            name: 'Cisco Meraki',
            link: '/latest/references-connectors-cisco-meraki',
          },
          {
            name: 'Cosmos DB',
            link: '/latest/references-connectors-cosmos-db',
          },
          {
            name: 'Custom CData',
            link: '/latest/references-connectors-custom-cdata',
          },
          {
            name: 'Custom SQL',
            link: '/latest/references-connectors-custom-sql',
          },
          {
            name: 'Dropbox',
            link: '/latest/references-connectors-dropbox',
          },
          {
            name: 'File System (LocalFiles)',
            link: '/latest/references-connectors-file-system',
          },
          {
            name: 'Freshservice',
            link: '/latest/references-connectors-freshservice',
          },
          {
            name: 'FTP',
            link: '/latest/references-connectors-ftp',
          },
          {
            name: 'Google BigQuery',
            link: '/latest/references-connectors-google-bigquery',
          },
          {
            name: 'Google Cloud Storage (GCS)',
            link: '/latest/references-connectors-google-cloud-storage-gcs',
          },
          {
            name: 'Google Drive',
            link: '/latest/references-connectors-google-drive',
          },
          {
            name: 'Google Sheets',
            link: '/latest/references-connectors-google-sheets',
          },
          {
            name: 'GraphQL',
            link: '/latest/references-connectors-graphql',
          },
          {
            name: 'IBM DB2',
            link: '/latest/references-connectors-ibm-db2',
          },
          {
            name: 'IBM Netezza',
            link: '/latest/r-ibm-netezza-connector',
          },
          {
            name: 'JIRA',
            link: '/latest/references-connectors-jira',
          },
          {
            name: 'JSON',
            link: '/latest/references-connectors-json',
          },
          {
            name: 'Kyuubi',
            link: '/latest/references-connectors-kyuubi',
          },
          {
            name: 'Local Files',
            link: '/latest/references-connectors-local-files',
          },
          {
            name: 'MariaDB',
            link: '/latest/references-connectors-mariadb',
          },
          {
            name: 'Microsoft Azure Gen2',
            link: '/latest/references-connectors-microsoft-azure-gen2',
          },
          {
            name: 'Microsoft OneDrive',
            link: '/latest/references-connectors-microsoft-onedrive',
          },
          {
            name: 'Microsoft SharePoint',
            link: '/latest/references-connectors-microsoft-sharepoint',
          },
          {
            name: 'Microsoft SQL Server',
            link: '/latest/references-connectors-microsoft-sql-server',
          },
          {
            name: 'Microsoft SQL Server (JTDS)',
            link: '/latest/references-connectors-microsoft-sql-server-jtds',
          },
          {
            name: 'MongoDB BI',
            link: '/latest/references-connectors-mongodb-bi',
          },
          {
            name: 'MySQL',
            link: '/latest/references-connectors-mysql',
          },
          {
            name: 'NetSuite - Saved Searches',
            link: '/latest/references-connectors-netsuite-saved-searches',
          },
          {
            name: 'NetSuite - SuiteAnalytics',
            link: '/latest/references-connectors-netsuite-suiteanalytics',
          },
          {
            name: 'NetSuite - SuiteQL',
            link: '/latest/references-connectors-netsuite-suiteql',
          },
          {
            name: 'NetSuite - Web Service',
            link: '/latest/netsuite-webservice-connector',
          },
          {
            name: 'OneLogin',
            link: '/latest/references-connectors-onelogin',
          },
          {
            name: 'Oracle',
            link: '/latest/references-connectors-oracle',
          },
          {
            name: 'Oracle B2C Service',
            link: '/latest/references-connectors-oracle-b2c-service',
          },
          {
            name: 'Oracle Cloud Applications (UCM)',
            link: '/latest/references-connectors-oracle-cloud-applications-ucm',
          },
          {
            name: 'Oracle Cloud Applications (BICC)',
            link: '/latest/references-connectors-oracle-cloud-applications-bicc',
          },
          {
            name: 'Oracle CPQ',
            link: '/latest/references-connectors-oracle-cpq',
          },
          {
            name: 'Oracle EPM',
            link: '/latest/references-connectors-oracle-epm',
          },
          {
            name: 'Oracle Essbase',
            link: '/latest/references-connectors-oracle-essbase',
          },
          {
            name: 'Oracle OTM/GTM',
            link: '/latest/references-connectors-oracle-otm-gtm',
          },
          {
            name: 'Oracle Sales Cloud',
            link: '/latest/references-connectors-oracle-sales-cloud',
          },
          {
            name: 'Oracle WMS',
            link: '/latest/references-connectors-oracle-wms',
          },
          {
            name: 'PostgreSQL',
            link: '/latest/references-connectors-postgresql',
          },
          {
            name: 'Presto',
            link: '/latest/references-connectors-presto',
          },
          {
            name: 'Salesforce v2',
            link: '/latest/references-connectors-salesforce-v2',
          },
          {
            name: 'SAP ERP',
            link: '/latest/references-connectors-sap-erp',
          },
          {
            name: 'SAP Hana',
            link: '/latest/references-connectors-sap-hana',
          },
          {
            name: 'SAP Sybase IQ',
            link: '/latest/r-sap-sybase-iq-connector',
          },
          {
            name: 'ServiceNow',
            link: '/latest/references-connectors-servicenow',
          },
          {
            name: 'SFTP',
            link: '/latest/references-connectors-sftp',
          },
          {
            name: 'Smartsheet',
            link: '/latest/references-connectors-smartsheet',
          },
          {
            name: 'Snowflake',
            link: '/latest/references-connectors-snowflake',
          },
          {
            name: 'Splunk',
            link: '/latest/references-connectors-splunk',
          },
          {
            name: 'Teradata',
            link: '/latest/r-teradata-connector',
          },
          {
            name: 'Vertica',
            link: '/latest/r-vertica-connector',
          },
          {
            name: 'XML',
            link: '/latest/references-connectors-xml',
          },
          {
            name: 'Zuora',
            link: '/latest/references-connectors-zuora',
          },
        ],
      },
      {
        name: 'Data Studio',
        link: '/latest/references-all-recipes',
        children: [
          {
            name: 'Enabling the Data Studio',
            link: '/latest/enabling-the-data-studio',
          },
          {
            name: 'Aggregation Recipe',
            link: '/latest/references-aggeregation-recipe',
          },
          {
            name: 'Change Type Recipe',
            link: '/latest/references-change-type-recipe',
          },
          {
            name: 'Data Quality Recipe',
            link: '/latest/references-data-quality-recipe',
          },
          {
            name: 'Filter Recipe',
            link: '/latest/references-filter-recipe',
          },
          {
            name: 'Formula Recipe',
            link: '/latest/references-formula-recipe',
          },
          {
            name: 'Fuzzy Join Recipe',
            link: '/latest/references-fuzzy-join-recipe',
          },
          {
            name: 'Join Recipe',
            link: '/latest/references-join-recipe',
          },
          {
            name: 'Python Recipe',
            link: '/latest/references-python-recipe',
          },
          {
            name: 'Rename Recipe',
            link: '/latest/references-rename-recipe',
          },
          {
            name: 'Sample Recipe',
            link: '/latest/references-sample-recipe',
          },
          {
            name: 'Save MV Recipee',
            link: '/latest/references-save-mv-recipe',
          },
          {
            name: 'Select Recipe',
            link: '/latest/references-select-recipe',
          },
          {
            name: 'Sort Recipe',
            link: '/latest/references-sort-recipe',
          },
          {
            name: 'Split Recipe',
            link: '/latest/references-split-recipe',
          },
          {
            name: 'SQL Recipe',
            link: '/latest/references-SQL-recipe',
          },
          {
            name: 'Union Recipe',
            link: '/latest/references-union-recipe',
          },
          {
            name: 'Unpivot Recipe',
            link: '/latest/references-unpivot-recipe',
          },
        ],
      },
      {
        name: 'Public API',
        link: '/latest/references-public-api',
        children: [
          {
            name: 'Public API v2',
            link: '/latest/references-public-api-v2',
            children: [
              {
                name: 'Create External Schema',
                link: '/latest/references-api-create-external-schema-endpoint-v2',
              },
              {
                name: 'Extract to External Table',
                link: '/latest/references-api-extract-to-external-table-endpoint-v2',
              },
              {
                name: 'Append to External Table',
                link: '/latest/references-api-append-to-external-table-endpoint-v2',
              },
              {
                name: 'Delete External Table',
                link: '/latest/references-api-delete-external-table-endpoint-v2',
              },
              {
                name: 'Delete External Schema',
                link: '/latest/references-api-delete-external-schema-endpoint-v2',
              },
              {
                name: 'List Catalog Content',
                link: '/latest/references-api-list-catalog-content-endpoint-v2',
              },
              {
                name: 'List Catalog Folder Content',
                link: '/latest/references-api-list-catalog-folder-content-endpoint-v2',
              },
              {
                name: 'Search Catalog Content',
                link: '/latest/references-api-search-catalog-endpoint-v2',
              },
              {
                name: 'Create Catalog Folder',
                link: '/latest/references-api-create-catalog-folder-endpoint-v2',
              },
              {
                name: 'Get SQLi Connectiont',
                link: '/latest/references-api-get-sql-connection-endpoint-v2',
              },
              {
                name: 'Schedule Dashboard Report',
                link: '/latest/references-api-send-report-endpoint-v2',
              },
              {
                name: 'Dashboard Prompts',
                link: '/latest/references-api-dashboard-prompts-endpoint-v2',
              },
              {
                name: 'Rename & Move Dashboards',
                link: '/latest/references-api-rename-move-dashboards-endpoint-v2',
              },
              {
                name: 'Insight Query',
                link: '/latest/references-api-insight-query-endpoint-v2',
              },
              {
                name: 'Execute Load Plan',
                link: '/latest/references-api-execute-load-plan-endpoint-v2',
              },
              {
                name: 'Load Plan Execution Status',
                link: '/latest/references-api-load-plan-execution-status-endpoint-v2',
              },
              {
                name: 'Query a Business View',
                link: '/latest/references-api-query-endpoint-v2',
              },
              {
                name: 'Load Schema',
                link: '/latest/references-api-load-schema-endpoint-v2',
              },
              {
                name: 'Schema Status',
                link: '/latest/references-api-schema-status-endpoint-v2',
              },
              {
                name: 'List Schemas',
                link: '/latest/references-api-list-schemas-endpoint-v2',
              },
              {
                name: 'List Schema Objects',
                link: '/latest/references-api-list-schema-objects-endpoint-v2',
              },
              {
                name: 'Reset Passwords',
                link: '/latest/references-api-reset-passwords-endpoint-v2',
              },
              {
                name: 'Transfer Ownership',
                link: '/latest/references-api-transfer-ownership-endpoint-v2',
              },
            ],
          },
        ],
      },
      {
        name: 'Security',
        link: '/latest/references-security',
        children: [
          {
            name: 'Client credentials for a Google APIs project',
            link: '/latest/references-security-client-credentials-for-a-google-apis-project',
          },
          {
            name: 'Enable Zookeeper SSL ',
            link: '/latest/references-security-enable-zookeeper-ssl',
          },
          {
            name: 'Enable MySQL SSL',
            link: '/latest/references-security-enable-mysql-ssl',
          },
          {
            name: 'Engine Audit',
            link: '/latest/references-engine-audit',
          },
          {
            name: 'HTTPS for Apache Tomcat with OpenSSL',
            link: '/latest/references-security-https-for-apache-tomcat-with-openssl',
          },
          {
            name: 'OAuth for Box',
            link: '/latest/references-security-oauth-box',
          },
          {
            name: 'OAuth for Dropbox',
            link: '/latest/references-security-oauth-dropbox',
          },
          {
            name: 'OAuth for Google API',
            link: '/latest/references-security-oauth-google-api',
          },
       {
            name: 'OAuth for Microsoft Azure Gen1',
            link: '/latest/references-security-oauth-microsoft-azure-gen1',
          },
          {
            name: 'OAuth for OneDrive',
            link: '/latest/references-security-oauth-onedrive',
          },
          {
            name: 'OAuth for Sharepoint',
            link: '/latest/references-security-oauth-sharepoint',
          },
          {
            name: 'OAuth for Smartsheet',
            link: '/latest/references-security-oauth-smartsheet',
          },
          {
            name: 'Security Roles',
            link: '/latest/references-security-roles',
          },
        ],
      },
      {
        name: 'SQL Interface',
        link: '/latest/references-sqli-all',
        children: [
          {
            name: 'SQL Interface',
            link: '/latest/references-sqli',
          },
          {
            name: 'Configure Spark with SQLi',
            link: '/latest/spark-config-sqli',
          },
          {
            name: 'Advanced SQL Interface',
            link: '/latest/references-advanced-sqli',
            children: [
              {
                name: 'Setup and Configuration',
                link: '/latest/references-advanced-sqli-setup-config',
              },
              {
                name: 'SparkX Metastore Sync',
                link: '/latest/references-sparkx-metastore-sync',
              },
              {
                name: 'Discovery of Non-Optimized Tables',
                link: '/latest/references-discovery-of-non-optimized-tables',
              },
              {
                name: 'SparkX Access to Cloud Storage',
                link: '/latest/references-sparkx-access-to-cloud-storage',
              },
              {
                name: 'Data Extraction via Advanced SQLi and Public API',
                link: '/latest/references-data-extraction-via-advanced-sqli-and-public-api'
              },
              {
                name: 'Scalability Modes',
                link: '/latest/references-advanced-sqli-scalability-modes',
              },
              {
                name: 'Advanced SQLi Ports',
                link: '/latest/references-advanced-sqli-ports',
              },
              {
                name: 'Custom SparkX Sizes',
                link: '/latest/references-advanced-sqli-spark-size',
              },
              {
                name: 'Notebook for Business Users',
                link: '/latest/references-notebook-for-business-users',
              },
            ],
          },
        ],
      },
      {
        name: 'Visualizations',
        link: '/latest/references-visualizations-all',
        children: [
          {
            name: 'Insights over Result Sets',
            link: '/latest/references-visualizations-insights-over-result-sets',
          },
          {
            name: 'Advanced Map',
            link: '/latest/references-visualizations-advanced-map',
          },
          {
            name: 'Aggregated Table',
            link: '/latest/references-visualizations-aggregated-table',
          },
          {
            name: 'Area',
            link: '/latest/references-visualizations-area',
          },
          {
            name: 'Area Range',
            link: '/latest/references-visualizations-area-range',
          },
          {
            name: 'Bar',
            link: '/latest/references-visualizations-bar',
          },
          {
            name: 'Bubble',
            link: '/latest/references-visualizations-bubble',
          },
          {
            name: 'Bubble Map',
            link: '/latest/references-visualizations-bubble-map',
          },
          {
            name: 'Column',
            link: '/latest/references-visualizations-column',
          },
          {
            name: 'Combination',
            link: '/latest/references-visualizations-combination',
          },
          {
            name: 'Combo Dual Axis',
            link: '/latest/references-visualizations-combo-dual-axis',
          },
          {
            name: 'Combo',
            link: '/latest/references-visualizations-combo',
          },
          {
            name: 'Donut',
            link: '/latest/references-visualizations-donut',
          },
          {
            name: 'Dual X-Axis',
            link: '/latest/references-visualizations-dual-x-axis',
          },
          {
            name: 'Funnel',
            link: '/latest/references-visualizations-funnel',
          },
          {
            name: 'Gauge',
            link: '/latest/references-visualizations-gauge',
          },
          {
            name: 'Heatmap',
            link: '/latest/references-visualizations-heatmap',
          },
          {
            name: 'KPI',
            link: '/latest/references-visualizations-kpi',
          },
          {
            name: 'Line',
            link: '/latest/references-visualizations-line',
          },
          {
            name: 'Line Time Series',
            link: '/latest/references-visualizations-line-time-series',
          },
          {
            name: 'Listing Table',
            link: '/latest/references-visualizations-listing-table',
          },
          {
            name: 'Map',
            link: '/latest/references-visualizations-map',
          },
          {
            name: 'Organizational',
            link: '/latest/references-visualizations-organizational',
          },
          {
            name: 'Packed Bubble',
            link: '/latest/references-visualizations-packed-bubble',
          },
          {
            name: 'Percent Area',
            link: '/latest/references-visualizations-percent-area',
          },
          {
            name: 'Percent Bar',
            link: '/latest/references-visualizations-percent-bar',
          },
          {
            name: 'Percent Column',
            link: '/latest/references-visualizations-percent-column',
          },
          {
            name: 'Percent Line',
            link: '/latest/references-visualizations-percent-line',
          },
          {
            name: 'Pie',
            link: '/latest/references-visualizations-pie',
          },
          {
            name: 'Pie Donut',
            link: '/latest/references-visualizations-pie-donut',
          },
          {
            name: 'Pivot Table',
            link: '/latest/references-visualizations-pivot-table',
          },
          {
            name: 'Pyramid',
            link: '/latest/references-visualizations-pyramid',
          },
          {
            name: 'Radial Bar',
            link: '/latest/references-visualizations-radial-bar',
          },
          {
            name: 'Rich Text',
            link: '/latest/references-visualizations-rich-text',
          },
          {
            name: 'Sankey',
            link: '/latest/references-visualizations-sankey',
          },
          {
            name: 'Scatter',
            link: '/latest/references-visualizations-scatter',
          },
          {
            name: 'Shapes',
            link: '/latest/references-visualizations-shapes',
          },
          {
            name: 'Solid Gauge',
            link: '/latest/references-visualizations-solid-gauge',
          },
          {
            name: 'Spider',
            link: '/latest/references-visualizations-spider',
          },
          {
            name: 'Stacked Area',
            link: '/latest/references-visualizations-stacked-area',
          },
          {
            name: 'Stacked Bar',
            link: '/latest/references-visualizations-stacked-bar',
          },
          {
            name: 'Stacked Column',
            link: '/latest/references-visualizations-stacked-column',
          },
          {
            name: 'Stacked Column and Line',
            link: '/latest/references-visualizations-stacked-column-line',
          },
          {
            name: 'Stacked Line',
            link: '/latest/references-visualizations-stacked-line',
          },
          {
            name: 'Sunburst',
            link: '/latest/references-visualizations-sunburst',
          },
          {
            name: 'Tag Cloud',
            link: '/latest/references-visualizations-tag-cloud',
          },
          {
            name: 'Time Series',
            link: '/latest/references-visualizations-time-series',
          },
          {
            name: 'Tornado',
            link: '/latest/references-visualizations-tornado',
          },
          {
            name: 'Treemap',
            link: '/latest/references-visualizations-treemap',
          },
          {
            name: 'Waterfall',
            link: '/latest/references-visualizations-waterfall',
          },
        ],
      },
      {
        name: 'Other',
        link: '/latest/references-other',
        children: [
          {
            name: 'Compatibility Matrix',
            link: '/latest/references-compatibility-matrix',
          },
          {
            name: 'Customize Incorta with CSS',
            link: '/latest/references-customize-incorta-with-css',
          },
          {
            name: 'Customize Incorta with Themes',
            link: '/latest/references-customize-incorta-with-themes',
          },
          {
            name: 'Decimal Data Support',
            link: '/latest/references-decimal-data-support',
          },
          {
            name: 'Null Handling',
            link: '/latest/references-null-handling',
          },
        ],
      },
    ],
  },
  {
    name: 'Integrations',
    link: '/latest/integrations-all',
    children: [
      {
        name: 'Microsoft Excel Add-in',
        link: '/latest/integrations-microsoft-excel-add-in',
      },
      {
        name: 'Microsoft Power BI',
        link: '/latest/integrations-microsoft-power-bi',
      },
      {
        name: 'Microsoft Teams',
        link: '/latest/integrations-microsoft-teams',
      },
      {
        name: 'Slack',
        link: '/latest/integrations-slack',
      },
      {
        name: 'Tableau for SQLi',
        link: '/latest/integrations-tableau',
      },
      {
        name: 'Tableau for Advanced SQLi',
        link: '/latest/integrations-tableau-advanced-sqli',
      },
      {
        name: 'XLCubed',
        link: '/latest/integrations-xlcubed',
      },
    ],
  },
  {
    name: 'Data applications',
    link: '/latest/blueprints-all',
    children: [
      {
        name: 'Cloud Data Apps',
        link: '/latest/blueprints-all-cloud',
        children: [
          {
            name: 'Incorta Data Delivery',
            link: '/latest/blueprints-data-delivery-all-cloud',
            children: [
              {
                name: 'Data Delivery for Azure Synapse',
                link: '/latest/configure-incorta-data-delivery-for-azure-synapse-cloud',
              },
              {
                name: 'Data Delivery for Google BigQuery',
                link: '/latest/configure-incorta-data-delivery-for-bigquery-cloud',
              },
              {
                name: 'Data Delivery of Oracle Cloud ERP for Azure Synapse',
                link: '/latest/blueprints-data-delivery-oracle-cloud-erp-synapse-cloud',
              },
              {
                name: 'Data Delivery of Oracle EBS for Azure Synapse',
                link: '/latest/blueprints-data-delivery-oracle-ebs-synapse-cloud',
              },
          ],
          },
            {
            name: 'Oracle Cloud ERP data app',
            link: '/latest/data-application-oracle-cloud-all-cloud',
            children: [
              {
                name: 'Installation and Configuration',
                link: '/latest/data-application-install-configure-oracle-erp-cloud-v2',
              },
              {
                name: 'Analyze Financials and Supply Chain',
                link: '/latest/data-application-oracle-financial-supply-chain-cloud',
              },
              {
                name: 'Analyze Human Capital Management',
                link: '/latest/data-application-oracle-cloud-hcm-cloud',
              },
              {
                name: 'FAQs and Troubleshooting',
                link: '/latest/data-application-oracle-cloud-faq-cloud',
              },

            ],
          },
          {
            name: 'Oracle EBS data app',
            link: '/latest/blueprints-oracle-ebs-incorta-cloud',
            children: [
              {
                name: 'Oracle EBS for Google Cortex Setup Guide',
                link: '/latest/blueprints-oracle-ebs-google-cortex-setup-guide',
              },
              {
                name: 'Analyze Oracle EBS Accounts Payable',
                link: '/latest/blueprints-oracle-ebs-accounts-payable-cloud',
              },
              {
                name: 'Analyze Oracle EBS Accounts Receivable',
                link: '/latest/blueprints-oracle-ebs-accounts-receivable-cloud',
              },
              {
                name: 'Analyze Oracle EBS Fixed Assets',
                link: '/latest/blueprints-oracle-ebs-fixed-assets-cloud',
              },
              {
                name: 'Analyze Oracle EBS General Ledger',
                link: '/latest/blueprints-oracle-ebs-general-ledger-cloud',
              },
              {
                name: 'Analyze Oracle EBS Inventory Management',
                link: '/latest/blueprints-oracle-ebs-inventory-management-cloud',
              },
              {
                name: 'Analyze Oracle EBS Order Management',
                link: '/latest/blueprints-oracle-ebs-order-management-cloud',
              },
              {
                name: 'Analyze Oracle EBS Purchasing',
                link: '/latest/blueprints-oracle-ebs-purchasing-cloud',
              },
              {
                name: 'Analyze Oracle EBS Projects',
                link: '/latest/blueprints-oracle-ebs-projects-cloud',
              },
              {
                name: 'Analyze Oracle EBS Human Resources',
                link: '/latest/blueprints-oracle-ebs-human-resources-cloud',
              },
              {
                name: 'Power BI dashboards for Oracle EBS',
                link: '/latest/blueprints-power-bi-dashboards-for-oracle-ebs-cloud',
              },
              {
                name: 'XLCubed Excel plugin dashboards for Oracle EBS',
                link: '/latest/blueprints-xlcubed-excel-plugin-dashboards-for-oracle-ebs-cloud',
              },
            ],
          },
          {
            name: 'Salesforce data app',
            link: '/latest/blueprints-salesforce-incorta-cloud',
            children: [
              {
                name: 'Analyze Finance',
                link: '/latest/blueprints-salesforce-finance-cloud',
              },
              {
                name: 'Analyze Sales',
                link: '/latest/blueprints-salesforce-sales-cloud',
              },
              {
                name: 'Analyze Marketing',
                link: '/latest/blueprints-salesforce-marketing-cloud',
              },
            ],
          },
          {
            name: 'BlackLine',
            link: '/latest/data-application-blackline-cloud',
            children: [
              {
                name: 'Account Reconciliations for Oracle EBS',
                link: '/latest/data-application-blackline-oracle-ebs-cloud',
              },
              {
                name: 'Account Reconciliations for Oracle Cloud ERP',
                link: '/latest/blueprints-blackline-account-reconciliations-oracle-erp-cloud',
              },
              {
                name: 'Account Reconciliations for SAP ECC',
                link: '/latest/data-application-blackline-sap-ecc-cloud',
              },
              {
                name: 'Account Reconciliations for SAP S/4HANA',
                link: '/latest/data-application-blackline-sap-s4-hana-cloud',
              },
            ],
          },
          {
            name: 'XLCubed Excel plugin dashboards for SAP ECC',
            link: '/latest/data-application-xlcubed-sap-ecc-cloud',
          },
          {
            name: 'NetSuite data app',
            link: '/latest/blueprints-netsuite-incorta-cloud',
          },
          {
            name: 'SAP S/4HANA Financials and Supply Chain data app',
            link: '/latest/data-application-sap-s4hana-financials-and-supply-chain-cloud',
          },
          {
            name: 'Data app Data Wizard',
            link: '/latest/blueprints-data-wizard-cloud',
          },
        ],
      },
      {
        name: 'On-Premises Data Apps',
        link: '/latest/blueprints-all-on-prem',
        children: [
          {
            name: 'Incorta Data Delivery',
            link: '/latest/blueprints-data-delivery-all-on-prem',
            children: [
              {
                name: 'Data Delivery for Azure Synapse',
                link: '/latest/configure-incorta-data-delivery-for-azure-synapse-on-prem',
              },
              {
                name: 'Data Delivery for Google BigQuery',
                link: '/latest/configure-incorta-data-delivery-for-bigquery-on-prem',
              },
              {
                name: 'Data Delivery of Oracle Cloud ERP for Azure Synapse',
                link: '/latest/blueprints-data-delivery-oracle-cloud-erp-synapse-on-prem',
              },
              {
                name: 'Data Delivery of Oracle EBS for Azure Synapse',
                link: '/latest/blueprints-data-delivery-oracle-ebs-synapse-on-prem',
              },
          ],
          },
          {
            name: 'Oracle Enterprise Resource Planning (ERP)',
            link: '/latest/ebs-on-prem',
            children: [
              {
                name: 'Install and Configure Oracle EBS data app for Incorta On-Premises',
                link: '/latest/blueprints-oracle-ebs-on-prem',
                children: [
                  {
                    name: 'Oracle EBS for Google Cortex Setup Guide',
                    link: '/latest/blueprints-oracle-ebs-google-cortex-setup-guide',
                  },
                  {
                    name: 'Analyze Oracle EBS Accounts Payable',
                    link: '/latest/blueprints-oracle-ebs-accounts-payable-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Accounts Receivable',
                    link: '/latest/blueprints-oracle-ebs-accounts-receivable-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Fixed Assets',
                    link: '/latest/blueprints-oracle-ebs-fixed-assets-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS General Ledger',
                    link: '/latest/blueprints-oracle-ebs-general-ledger-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Projects',
                    link: '/latest/ebs-financial-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Advanced Supply Chain Planning',
                    link: '/latest/ebs-implement-supply-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Inventory Management',
                    link: '/latest/blueprints-oracle-ebs-inventory-management-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Order Management',
                    link: '/latest/blueprints-oracle-ebs-order-management-on-prem',
                  },
                  {
                    name: 'Analyze Oracle EBS Purchasing',
                    link: '/latest/blueprints-oracle-ebs-purchasing-on-prem',
                  },
                ],
              },
              {
                name: 'Install and Configure Oracle Cloud ERP data app for Incorta On-Premises',
                link: '/latest/data-application-install-configure-oracle-erp-cloud-v2',
              },
            ],
          },
          {
            name: 'Install and Configure NetSuite for Incorta',
            link: '/latest/blueprints-netsuite-incorta-on-prem',
          },
          {
            name: 'BlackLine data app',
            link: '/latest/data-application-install-configure-blackline-on-prem',
          },
        ],
      }
    ]
  },
  {
     name: 'Releases',
     link: '/latest/releases',
     children: [
       {
         name: 'Cloud-Only Releases',
         link: '/latest/cloud-releases',
         children: [
           {
             name: '2024.1.0',
             link: '/latest/release-notes-2024-1-0',
           },
           {
             name: "What's New 2024.1.0",
             link: '/latest/whats-new-2024-1-0',
           },
           {
             name: 'Cloud Platform Updates',
             link: '/latest/cloud-platform-release-notes',
           },
         ],
       },
       {
         name: 'On-Premises-Only Releases',
         link: '/latest/on-premises-releases',
         children: [
           {
             name: '2024.1.3',
             link: '/latest/release-notes-2024-1-3-on-prem',
           },
         ],
       },
       {
         name: '2024.1 Maintenance Packs ',
         link: '/latest/2024-1-maintenance-packs',
         children: [
           {
             name: '2024.1.4',
             link: '/latest/release-notes-2024-1-4',
           },
           {
             name: '2024.1.5',
             link: '/latest/release-notes-2024-1-5',
           },
           {
             name: '2024.1.6',
             link: '/latest/release-notes-2024-1-6',
           },
         ],
       },
       {
         name: '2024.7.x',
         link: '/latest/release-notes-2024-7-0',
       },
       {
         name: 'Known Issues',
         link: '/latest/known-issues',
       },


     ],
   },
   {
     name: 'Incorta Premium',
     link: '/latest/incorta-premium',
     children: [
      {
        name: 'Incorta Copilot',
        link: '/latest/incorta-copilot',
      }, 
      {
         name: 'Incorta Data Studio',
         link: '/latest/references-all-recipes',
       },
       {
         name: 'Notebook for Business Users',
         link: '/latest/references-notebook-for-business-users',
       },
       {
         name: 'Spark SQL View',
         link: '/latest/concepts-incorta-spark-sql-view',
       },
     ],
   },
];

module.exports = tocLatest;
